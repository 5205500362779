import { api } from "./Configs/AxiosConfigs"; 

export const GenericAPI = {
  getAll: async function (route) {
    const response = await api.request({
      url: "/" + route + "/",
      method: "GET",
    });
    return response.data;
  },
  get: async function (route, id) {
    const response = await api.request({
      url: "/" + route + "/" + id,
      method: "GET",
    });
 
    return response.data;
  },
  post: async function (route, item) {
    const response = await api.request({
      url: "/" + route,
      method: "POST",
      data: item,
    }); 
    return response.data;
  },
  update: async function (route,item) {
    const response = await api.request({
      url: "/" + route + "/" + item.id,
      method: "PUT",
      data: item, 
    }); 
    return response.data;
  },
  delete: async function  (route, id) {
    const response = await api.request({
      url: "/" + route + "/" + id,
      method: "DELETE", 
    });
  
    return response.data;
  },
};
 
