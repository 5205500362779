import React, { useEffect, useState, useRef } from "react";
import MainLayout from "../../Layouts/MainLayout";
import { Container, Alert, Button, Input } from "design-react-kit"; 
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import { GenericAPI } from "../../Apis/GenericAPI";
import listAPI from "../../Apis/EnumAPI";

export default function CreateMunicipality(props) {
  const navigate = useNavigate();
  const createFormRef = useRef(null);

  const [show, setShow] = useState(false);
  const [variant, setVariant] = useState("");
  const [newTenat, setNewTenat] = useState({});
  const [message, setMessage] = useState("");
  const [municipality, setMunicipality] = useState([]);
  const [options, setOptions] = useState([]);

  function back() {
    navigate(-1);
  }

  function getAlert() {
    if (show) {
      return (
        <Alert variant={variant} onClose={() => setShow(false)} dismissible>
          <p>{message}</p>
        </Alert>
      );
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(createFormRef.current);
    const formDataJSON = Object.fromEntries(formData);

    try {
      await GenericAPI.post(listAPI.Tenants,formDataJSON).then(
        (response) => {
          if (response.success) {
            setVariant("success");
            setMessage("Operazione eseguita correttamente!!");
            setShow(true);

            setTimeout(() => {
              setShow(false);
              back()
            }, 5000);
          }else{
            setVariant("danger");
            setMessage("Operazione non riuscita!! " + response.message);
            setShow(true);
      
            setTimeout(() => {
              setShow(false);
            }, 5000);
          }
        }
      );

 
    } catch (e) {
      setVariant("danger");
      setMessage("Operazione non riuscita!! " + e);
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 5000);
    }
  };

  useEffect(() => {
    municipalities();
  }, []);

  const municipalities = async () => {
    try {
      await GenericAPI.getAll(listAPI.Municipalities).then(
        (response) => {


          if (response.success) {
            setMunicipality(response.data);
            var options = response.data.map((data) => ({
              value: data.code,
              label: data.description + " (" + data.code + ")",
            }));
            setOptions(options);
          }else{
            setVariant("danger");
            setMessage("Operazione non riuscita!! " + response.message);
            setShow(true);
      
            setTimeout(() => {
              setShow(false);
            }, 5000);
          }


        }
      );
      
    } catch (err) {
      console.log(err);
    }
  };

  const change = async (e) => {
    municipality.map((data) => {
      if (data.code == e.value) setNewTenat(data);
    });
  };

  return (
    <MainLayout>
      <Container className="my-5">
        <h2 className="fw-normal mb-5"> Aggiungi Comune </h2> {getAlert()}
        <main>
          <div className="py-12">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                <div className="p-6 bg-white border-b border-gray-200">
                  <div className="row">
                    <div className="col-6">
                      <div className="flex items-center justify-between mb-6">
                        <span key="link">
                          <Button onClick={back} color="primary">
                            Indietro
                          </Button>{" "}
                        </span>
                      </div>
                    </div>
                    <div className="col-6">
                      <Select options={options} onChange={change} />
                    </div>
                  </div>

                  <br />
                  <br />
                </div>
                <div className="p-6 bg-white border-b border-gray-200">
                  <form
                    ref={createFormRef}
                    name="createForm"
                    id="createForm"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex flex-col">
                      <div className="mb-4">
                        <Input
                          type="text"
                          label="Id"
                          name="id"
                          value={newTenat.code}
                        />
                        <span className="text-red-600"></span>
                      </div>
                      <div className="mb-0">
                        <Input
                          type="text"
                          label="Descrizione"
                          name="description"
                          value={newTenat.description}
                        />
                        <span className="text-red-600"></span>
                      </div>
                      <div className="mb-0">
                        <Input
                          type="text"
                          label="Regione"
                          name="country"
                          value={newTenat.country}
                        />
                        <span className="text-red-600"></span>
                      </div>
                      <div className="mb-0">
                        <Input type="text" label="Dominio" name="domain" />
                        <span className="text-red-600"></span>
                      </div>
                    </div>
                    <div className="mt-4">
                      <Button color="secondary">Salva</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Container>
    </MainLayout>
  );
}
