import React, { useEffect, useState, useRef } from "react";
import MainLayout from "../../Layouts/MainLayout";
import { Button, Input, Toggle, Alert } from "design-react-kit";
import { Container } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
 
import { GenericAPI } from "../../Apis/GenericAPI";
import listAPI from "../../Apis/EnumAPI";

export default function EditService(props) {
  const navigate = useNavigate();
  let params = useParams(); 
  const [service, setService] = useState({});
  const [show, setShow] = useState(false);
  const [variant, setVariant] = useState("");
  const [message, setMessage] = useState("");
  const createFormRef = useRef(null);

  function back() {
    navigate(-1);
  }

  function getAlert() {
    if (show) {
      return (
        <Alert variant={variant} onClose={() => setShow(false)} dismissible>
          <p>{message}</p>
        </Alert>
      );
    }
  }
 
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("start");
    try {
      const formData = new FormData(createFormRef.current);
      const formDataJSON = Object.fromEntries(formData);
      const res = await GenericAPI.update(listAPI.Services,formDataJSON);
   
      if (res.success) {
        setVariant("success");
        setMessage("Operazione eseguita correttamente!!");
        setShow(true);

        setTimeout(() => {
          setShow(false);
        }, 5000);
      }
      back();
    } catch (e) { 
      setVariant("danger");
      setMessage("Operazione non riuscita!!");
      setShow(true);

      setTimeout(() => {
        setShow(false);
      }, 5000);
    }
  };

  const onChangeEdit = (e) => {
    switch (e.target.name) {
      case "id":
        setService((prev) => { 
                    return { ...prev, id: e.target.value };
        });
        break;
      case "title":
        setService((prev) => { 
          return { ...prev, title: e.target.value };
        });
        break;
      case "path":
        setService((prev) => { 
          return { ...prev, path: e.target.value };
        });
        break;
      case "status":
       
        setService((prev) => {
           
          return { ...prev, status: e.target.value };
        });
        break;
      default:
        break;
    }
  };
 
  useEffect(() => {
    getService();
  }, []);


  const getService = async () => {
    try {
      const response = await GenericAPI.get(listAPI.Services, params.id);
      setService(response.data); 
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MainLayout>
      <Container className="my-5">
        <h2 className="fw-normal mb-5"> Modifica Servizio </h2>{" "}
        <main>
        <div className="row">
          <div className="col-12">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                <div className="p-6 bg-white border-b border-gray-200">
                  <div className="flex items-center justify-between mb-6">
                    <span key="link">
                      <Button onClick={back} color="primary">
                        Indietro
                      </Button>{" "}
                    </span>
                  </div>
                  <br />
                  <br />
                </div>
                <div className="p-6 bg-white border-b border-gray-200">
                  <form
                    ref={createFormRef}
                    name="createForm"
                    id="createForm"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex flex-col">
                      <div className="mb-4">
                        <Input
                          type="text"
                          label="Id"
                          name="id"
                          value={service?.id ? service?.id : ""}
                          onChange={onChangeEdit}
                        />
                        <span className="text-red-600"></span>
                      </div>
                      <div className="mb-0">
                        <Input
                          type="text"
                          label="Titolo"
                          name="title"
                          value={service?.title ? service?.title : ""}
                          onChange={onChangeEdit}
                        />
                        <span className="text-red-600"></span>
                      </div>
                      <div className="mb-0">
                        <Input
                          type="text"
                          label="Path"
                          name="path"
                          value={service?.path}
                          onChange={onChangeEdit}
                        />
                        <span className="text-red-600"> </span>
                      </div>
                      <div className="mb-0">
                         <Toggle
                           type="checkbox"
                          name="status"  
                           label="Stato"
                           defaultChecked
                           value={service?.status}
                          onChange={e => onChangeEdit(e)}
                        />
 
                        <span className="text-red-600"> </span>
                      </div>
                    </div>
                    <div className="mt-4">
                      <Button color="secondary">Salva</Button>
                    </div>
                  </form>
                  {getAlert()}
                </div>
              </div>
            </div>
            
          </div>
 
          </div>
        </main>
      </Container>
    </MainLayout>
  );
}
