import React, { useEffect, useState } from "react";

import { GenericAPI } from "../../Apis/GenericAPI";
import MainLayout from "../../Layouts/MainLayout";
import { Table, Icon, Button } from "design-react-kit";

import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import listAPI from "../../Apis/EnumAPI";
export default function IndexMunicipalities(props) {
  const navigate = useNavigate();
  const [tenants, setTenants] = useState([]);

  useState(false);
 
  const view = (e) => {
    navigate(`/view-municipality/${e.currentTarget.id}`);
  };

  const add = () => {
    navigate("/create-municipality");
  };

  useEffect(() => {
    tenents();
  }, []);

  const tenents = async () => {
    try {
       await GenericAPI.getAll(listAPI.Tenants).then((response) => {
         if (response.success) {
          setTenants(response.data);        }
      });
      
    } catch (err) {console.log(err)}
  };

  return (
    <MainLayout>
      <Container className="my-5">
        <h2 className="fw-normal mb-5"> Comuni </h2>{" "}
        <main>
          <div className="py-12">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                <div className="p-6 bg-white border-b border-gray-200">
                  <div className="flex items-center justify-between mb-6">
                    <Button
                      onClick={add}
                      tabIndex="-1"
                      color="primary"
                      size="xs"
                      icon
                    >
                      <Icon icon="it-plus" color="white" />
                    </Button>
                  </div>

                  <br />
                  <br />
                  <Table striped>
                    <thead>
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Comune</th>
                        <th scope="col">Azione</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tenants?.map(({ id, description }) => (
                        <tr key={Math.random() * 31 * Math.random() * 11}>
                          <th scope="row">{id}</th>
                          <td>{description}</td>
                          <td> 
                            <Button
                              onClick={view}
                              id={id}
                              tabIndex="-1"
                              color="warning"
                              size="xs"
                              icon
                            >
                              <Icon icon="it-password-visible" color="black" />
                            </Button>
                          </td>
                        </tr>
                      ))}
                      {tenants?.length === 0 && (
                        <tr>
                          <td colSpan="4">Nessun comune trovato.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Container>
    </MainLayout>
  );
}
