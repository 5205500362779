import React, {  useState } from "react";
import UserProfile from "../Components/UserProfile";

function MainLayout(props) {
   

  const renderProfile = () => {
    const user = JSON.parse(localStorage.getItem("user"));
 

      return <UserProfile auth={user} />;
  }

  return (
    <div>
      <div className="skiplink">
        <a className="visually-hidden-focusable" href="#main-container">
          Vai ai contenuti
        </a>
        <a className="visually-hidden-focusable" href="#footer">
          Vai al footer
        </a>
      </div>
      <header
        className="it-header-wrapper"
        data-bs-target="#header-nav-wrapper"
      >
        <div className="it-header-slim-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="it-header-slim-wrapper-content">
                  <a
                    className="d-lg-block navbar-brand"
                    target="_blank"
                    href="\"
                    aria-label="Vai al portale {Nome della Regione} - link esterno - apertura nuova scheda"
                    title="Vai al portale Nemea Sistemi"
                  >
                    Nemea Sistemi
                  </a>
                  <div className="it-header-slim-right-zone" role="navigation">
                    <div className="nav-item dropdown"></div>
                    {
                      renderProfile(props.auth)
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="it-nav-wrapper">
          <div className="it-header-center-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="it-header-center-content-wrapper">
                    <div className="it-brand-wrapper">
                      <a href="#">
                        <svg className="icon" aria-hidden="true">
                          <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-pa" />
                        </svg>
                        <div className="it-brand-text">
                          <div className="it-brand-title">
                            Portale Servizi Online
                          </div>
                        </div>
                      </a>
                    </div>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="it-header-navbar-wrapper" id="header-nav-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="navbar navbar-expand-lg has-megamenu">
                    <button
                      className="custom-navbar-toggler"
                      type="button"
                      aria-controls="nav4"
                      aria-expanded="false"
                      aria-label="Mostra/Nascondi la navigazione"
                      data-bs-target="#nav4"
                      data-bs-toggle="navbarcollapsible"
                    >
                      <svg className="icon">
                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-burger" />
                      </svg>
                    </button>
                    <div className="navbar-collapsable" id="nav4">
                      <div className="overlay" style={{ display: "none" }} />
                      <div className="close-div">
                        <button className="btn close-menu" type="button">
                          <span className="visually-hidden">
                            Nascondi la navigazione
                          </span>
                          <svg className="icon">
                            <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-close-big" />
                          </svg>
                        </button>
                      </div>
                      <div className="menu-wrapper">
                        <a href="#" className="logo-hamburger">
                          <svg className="icon" aria-hidden="true">
                            <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-pa" />
                          </svg>
                          <div className="it-brand-text">
                            <div className="it-brand-title">
                              Nome del Comune
                            </div>
                          </div>
                        </a>
                        <nav aria-label="Principale">
                          <ul
                            className="navbar-nav"
                            data-element="main-navigation"
                          >
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                href="/municipalities" 
                              >
                                <span>Comuni</span>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                href="/services" 
                              >
                                <span>Servizi</span>
                              </a>
                            </li>  
                          </ul>
                        </nav>
               
              
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main>{props.children}</main>
      <footer className="it-footer" id="footer">
        <div className="it-footer-main">
          <div className="container">
            <div className="row">
              <div className="col-12 footer-items-wrapper logo-wrapper">
                <img
                  className="ue-logo"
                  src="../assets/images/logo-eu-inverted.svg"
                  alt="logo Unione Europea"
                />
                <div className="it-brand-wrapper">
                  <a href="\">
                    <svg className="icon" aria-hidden="true">
                      <use xlinkHref="../assets/bootstrap-italia/dist/svg/sprites.svg#it-pa" />
                    </svg>
                    <div className="it-brand-text">
                      <h2 className="no_toc">Nemea Sistemi</h2>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default MainLayout;