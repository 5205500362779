import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import ProtectedRoute from "./util/ProtectedRoute";
import reportWebVitals from "./reportWebVitals";
import Login from "./Pages/Auth/Login";
import CreateMuniciaplity from "./Pages/Municipality/Create";
import EditMuniciaplity from "./Pages/Municipality/Edit";
import ViewMuniciaplity from "./Pages/Municipality/View";
import Dashboard from "./Pages/Municipality/Index";
import IndexMunicipalities from "./Pages/Municipality/Index";
import EditService from "./Pages/Service/Edit";
import CreateService from "./Pages/Service/Create";
import IndexServices from "./Pages/Service/Index";
import NotFound from "./Pages/NotFound";
import CreateOperator from "./Pages/Operator/Create";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={"/"}>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<App />}>
          <Route
            path=""
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/municipalities"
            element={
              <ProtectedRoute>
                <IndexMunicipalities />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-municipality"
            element={
              <ProtectedRoute>
                <CreateMuniciaplity />
              </ProtectedRoute>
            }
          />
          <Route
            path="/edit-municipality/:id"
            element={
              <ProtectedRoute>
                <EditMuniciaplity />
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-municipality/:id"
            element={
              <ProtectedRoute>
                <ViewMuniciaplity />
              </ProtectedRoute>
            }
          />
          <Route
            path="/services"
            element={
              <ProtectedRoute>
                <IndexServices />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-service"
            element={
              <ProtectedRoute>
                <CreateService />
              </ProtectedRoute>
            }
          />
          <Route
            path="/edit-service/:id"
            element={
              <ProtectedRoute>
                <EditService />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-operator/:tenant_id"
            element={
              <ProtectedRoute>
                <CreateOperator />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
