import React, { useEffect, useState } from "react";
 
import MainLayout from "../../Layouts/MainLayout";
import { Table, Icon, Button } from "design-react-kit";

import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import listAPI from "../../Apis/EnumAPI";
import { GenericAPI } from "../../Apis/GenericAPI";
export default function IndexServices(props) {
   
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
 

  const modify = (e) => { 
    navigate(`/edit-service/${e.currentTarget.id}`);

  }

  const add = () => {
    navigate("/create-service");
  }

  useEffect(() => {
    getService();
  }, []);


  const getService = async () => {
    try {
      const response = await GenericAPI.getAll(listAPI.Services);
      setServices(response.data); 
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MainLayout>
      <Container className="my-5">
        <h2 className="fw-normal mb-5"> Servizi </h2>{" "}
        <main>
          <div className="py-12">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                <div className="p-6 bg-white border-b border-gray-200">
                  <div className="flex items-center justify-between mb-6">
                    <Button
                      onClick={add}
                      tabIndex="-1"
                      color="primary"
                      size="xs"
                      icon
                    >
                      <Icon icon="it-plus" color="white" />
                    </Button>
                  </div>

                  <Table striped>
                    <thead>
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Titolo</th>
                        <th scope="col">Path</th>
                        <th scope="col">Stato</th>
                        <th scope="col">Azione</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        services?.map(({ id, title, path, status }) => (
                          <tr key={Math.random() * 31 * Math.random() * 11}>
                            <th scope="row">{id}</th>
                            <td>{title}</td>
                            <td>{path}</td>

                            <td> {status ? (
                <Icon icon="it-check" color="green" />
              ) : (
                <Icon icon="it-ban" color="red" />
              )}</td>

                            <td>
                              <Button
                                onClick={modify}
                                id={id}
                                tabIndex="-1"
                                color="primary"
                                size="xs"
                                icon
                              >
                                <Icon icon="it-pencil" color="white" />
                              </Button>
                            </td>
                          </tr>
                      ))}
                      {
                        services?.length === 0 && (
                          <tr>
                            <td colSpan="4">No contacts found.</td>
                          </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Container>
    </MainLayout>
  );
}
