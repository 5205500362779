import React, { useEffect, useState, useRef } from "react";
import MainLayout from "../../Layouts/MainLayout";
import { Container, Alert, Button, Icon } from "design-react-kit";
import { useParams, useNavigate } from "react-router-dom";

import { GenericAPI } from "../../Apis/GenericAPI";
import listAPI from "../../Apis/EnumAPI";

export default function ViewMunicipality(props) {
  const navigate = useNavigate();
  let params = useParams();

  const [tenant, setTenant] = useState({});
  const [domain, setDomain] = useState({});
  const [services, setServices] = useState([]);
  const [operators, setOperators] = useState([]);
  const [show, setShow] = useState(false);
  const [variant, setVariant] = useState("");
  const [message, setMessage] = useState("");

  function back() {
    navigate(-1);
  }

  function getAlert() {
    if (show) {
      return (
        <Alert variant={variant} onClose={() => setShow(false)} dismissible>
          <p>{message}</p>
        </Alert>
      );
    }
  }

  useEffect(() => {
    getTenent();
  }, []);

  const getTenent = async () => {
    try {
      const response = await GenericAPI.get(listAPI.Tenants, params.id);
      setTenant(response.data.tenant);
      setDomain(response.data.domain);
      setServices(response.data.services);
      setOperators(response.data.operators);
    } catch (err) {
      setVariant("danger");
      setMessage("Operazione non riuscita!! " + err);
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 5000);
    }
  };

  const modify = (e) => {
    navigate(`/edit-municipality/${e.currentTarget.id}`);
  };

  const addOperator = (e) => {
    navigate(`/create-operator/` + tenant.id);
  };

  function renderServices() {
    if (services.length > 0) {
      return services?.map((data) => {
        return (
          <div className="row">
            <div className="col-2  text-center">{data.id}</div>
            <div className="col-8">{data.title}</div>
            <div className="col-2  text-center">
              {data.status ? (
                <Icon icon="it-check" color="green" />
              ) : (
                <Icon icon="it-ban" color="red" />
              )}
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="row">
          <div className="col-12  text-center">Nessun servizio presente</div>
        </div>
      );
    }
  }

  function renderOperators() {
    if (operators.length > 0) {
      return operators?.map((data) => {
        return (
          <div className="row">
            <div className="col-5 ">{data.name}</div>
            <div className="col-5">{data.email}</div>
            <div className="col-2   text-center">{data.role}</div>
          </div>
        );
      });
    } else {
      return (
        <div className="row">
          <div className="col-12  text-center">Nessun operatore presente </div>
        </div>
      );
    }
  }

  return (
    <MainLayout>
      <Container className="my-5 p-10">
        <h2 className="fw-normal mb-5"> {tenant?.description} </h2> {getAlert()}
        <main>
          <div className="row">
            <div className="col-12">
              <div className="p-6 bg-white border-b border-gray-200">
                <div className="flex items-center justify-between mb-6">
                  <span key="link">
                    <Button onClick={back} color="primary">
                      Indietro
                    </Button>{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 mt-20">
              <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="bg-white overflow-hidden shadow-lg lg:rounded-lg p-3">
                  <div className="row bg-white border-b border-gray-200">
                    <div className="col-6">
                      <div className="p-6">
                        <div className="row">
                          <div className="col-8 mb-4 border-bottom">
                            {" "}
                            <h4>Info</h4>
                          </div>
                          <div className="col-4 mb-4 border-bottom">
                            <Button
                              onClick={modify}
                              id={tenant?.id ? tenant?.id : ""}
                              tabIndex="-1"
                              color="danger"
                              size="xs"
                              icon
                              className="pt-1"
                            >
                              <Icon icon="it-pencil" color="white" />
                            </Button>
                          </div>

                          <div className="col-3">
                            <b>ID </b>
                          </div>
                          <div className="col-9">
                            {tenant?.id ? tenant?.id : ""}
                          </div>

                          <div className="col-3">
                            <b>Comune </b>
                          </div>
                          <div className="col-9">
                            {tenant?.description ? tenant?.description : ""}
                          </div>

                          <div className="col-3">
                            <b>Regione </b>
                          </div>
                          <div className="col-9">
                            {tenant?.country ? tenant?.country : ""}
                          </div>

                          <div className="col-3">
                            <b>Dominio </b>
                          </div>
                          <div className="col-9">
                            {domain?.domain ? domain?.domain : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-8 mb-4 border-bottom">
                          {" "}
                          <h4>Utenti</h4>
                        </div>
                        <div className="col-4 mb-4 border-bottom">
                          <Button
                            onClick={addOperator}
                            id={tenant?.id ? tenant?.id : ""}
                            tabIndex="-1"
                            color="danger"
                            size="xs"
                            icon
                            className="pt-1"
                          >
                            <Icon icon="it-plus" color="white" />
                          </Button>
                        </div>

                        <div className="col-5">
                          <b>Nome </b>
                        </div>
                        <div className="col-5">
                          <b>Email </b>
                        </div>
                        <div className="col-2">
                          <b>Ruolo </b>
                        </div>

                        {renderOperators()}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mt-20">
                    <div className="row">
                      <div className="col-8 mt-4 mb-4 border-bottom">
                        {" "}
                        <h4>Servizi</h4>
                      </div>
                      <div className="col-4 mt-4 mb-4 border-bottom">
                        <Button
                          onClick={modify}
                          id={tenant?.id ? tenant?.id : ""}
                          tabIndex="-1"
                          color="danger"
                          size="xs"
                          icon
                          className="pt-1"
                        >
                          <Icon icon="it-pencil" color="white" />
                        </Button>
                      </div>

                      <div className="col-2">
                        <b>Codice </b>
                      </div>
                      <div className="col-8">
                        <b>Descrizione </b>
                      </div>
                      <div className="col-2">
                        <b>Stato </b>
                      </div>

                      {renderServices()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Container>
    </MainLayout>
  );
}
