 
import MainLayout from "../Layouts/MainLayout";
import { Container,   } from "design-react-kit"; 

export default function NotFound(props) {
 

 

    return (
        <MainLayout>
        <Container className="my-5">
            <h1>404 Not Found</h1>
            </Container>
        </MainLayout>
    );
}
