const listAPI = Object.freeze({
    Tenants: 'tenants',
    Municipalities: 'municipalities', 
    Services: 'services', 
    Login: 'login', 
    SPIDlogin: 'SPIDlogin',
    Configuration: 'configuration',
    Operators: 'operator',
  })

  export default listAPI