import axios from "axios"
 
export const api = axios.create({
  headers: {
    Authorization : `Bearer ${localStorage.getItem("access_token")}`
    },
    withCredentials: false,
    baseURL: window.location.protocol + "//"+ window.location.hostname +"/api",
    //baseURL: "http://portale.nemea.cloud:9001/api",
    //baseURL: "http://backoffice-test.localhost/api"
    //http://servizi-test.localhost/api/dashboard/
    //baseURL: process.env.REACT_APP_BASE_URL
})



// defining a custom error handler for all APIs
const errorHandler = (error) => {
  const statusCode = error.response?.status

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})