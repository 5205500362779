import React, { useRef } from "react";
import MainLayout from "../../Layouts/MainLayout";
import { Button, Input } from "design-react-kit";
import { Container } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { GenericAPI } from "../../Apis/GenericAPI";
import listAPI from "../../Apis/EnumAPI";

export default function CreateOperator(props) {
  const navigate = useNavigate();
  let params = useParams();
  const createFormRef = useRef(null);

  const back = () => {
    navigate(-1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(createFormRef.current);
    const formDataJSON = Object.fromEntries(formData);

    try {
      const res = await GenericAPI.post(
        listAPI.Operators + "/" + params.tenant_id,
        formDataJSON
      );
      back();
    } catch (e) {
      console.log("Creation error: ", e);
      alert("Oops! Some error occured.");
    }
  };

  return (
    <MainLayout>
      <Container className="my-5">
        <h2 className="fw-normal mb-5"> Crea operatore </h2>{" "}
        <main>
          <div className="py-12">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                <div className="p-6 bg-white border-b border-gray-200">
                  <div className="flex items-center justify-between mb-6">
                    <span key="link">
                      <Button onClick={back} color="primary">
                        Indietro
                      </Button>{" "}
                    </span>
                  </div>
                  <br />
                  <br />
                </div>
                <div className="p-6 bg-white border-b border-gray-200">
                  <form
                    ref={createFormRef}
                    name="createForm"
                    id="createForm"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex flex-col">
                      <div className="mb-4">
                        <Input type="text" label="Nome" name="name" />
                        <span className="text-red-600"></span>
                      </div>
                      <div className="mb-0">
                        <Input
                          type="email"
                          label="Email"
                          name="email"
                        />
                        <span className="text-red-600"></span>
                      </div>
              
                      <div className="mb-0">
                        <Input type="text" label="Ruolo" name="role" />
                        
                        <span className="text-red-600"></span>
                      </div>
                    </div>
                    <Input type="hidden"   name="password" value={Math.random() * 31 * Math.random() * 11} />
                    <div className="mt-4">
                      <Button color="secondary">Salva</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Container>
    </MainLayout>
  );
}
